import { TextField } from '@mui/material'
import { useController, UseControllerProps } from 'react-hook-form'

interface Props extends UseControllerProps{
    label: string
    isNumber?: boolean
    disabled?: boolean
}


export default function FormText(props: Props) {
    const {fieldState, field} = useController({...props, defaultValue: ''})
    if (props.isNumber) 
        return (
            <TextField
                {...props}
                {...field}
                fullWidth        
                type='number'
                variant='outlined'
                disabled={props.disabled}
                error={!!fieldState.error}
                helperText={fieldState.error?.message?.toString()}
            />
        )
    return (
        <TextField
            {...props}
            {...field}
            fullWidth        
            variant='outlined'
            disabled={props.disabled}
            error={!!fieldState.error}
            helperText={fieldState.error?.message?.toString()}
        />
    )
}
