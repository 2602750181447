import { useState } from 'react'
import {  FormControl, FormHelperText, Grid, Input, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useAppSelector } from "../hooks"
import { Lookup } from "../models/lookups"


interface Props {
    register: any
    errors: any
    setValue: any
    ft: number
    ins: number
    isDisabled?: boolean
}

export default function FormHeightSelect({register, errors, setValue, ft, ins, isDisabled}: Props) {    
    const { lookups } = useAppSelector(state => state.calculator)
    const [unit, setUnit] = useState("fi")
    const [height, setHeight] = useState(1)
    const [feet, setFeet] = useState(ft)
    const [inches, setInches] = useState(ins)

    const onChangeHeight = (e:any) => { 
        switch(e.target.name) {
            case 'ddlFeet':
                setFeet(e.target.value)
                setValue('heightInches', (e.target.value*12)+inches)
                return
            case 'ddlInches':
                setInches(e.target.value)
                setValue('heightInches', ((feet*12)+parseInt(e.target.value)))
                return
            case 'txtHeight':
                setHeight(e.target.value)
                setValue('heightInches', unit === 'cm' ? Math.round(parseInt(e.target.value)*0.3937008) : parseInt(e.target.value))
                return
        }
    }

    const onChangeUnits = (e:any) => {
        setUnit(e.target.value)
        switch(e.target.value) {
            case "fi":
                setValue('heightInches', ((feet*12)+inches))
                return
            case "cm":
                setValue('heightInches', Math.round(height*0.3937008))
                return
            default:
                setValue('heightInches', height)
                return
        }
    }

    const feetSelect = () => (<Select name='ddlFeet' disabled={isDisabled} error={!!errors?.heightInches} onChange={(e:any) => onChangeHeight(e)} value={feet} fullWidth>
        <MenuItem key={0} value={'0'}>0</MenuItem>
        <MenuItem key={1} value={'1'}>1</MenuItem>
        <MenuItem key={2} value={'2'}>2</MenuItem>
        <MenuItem key={3} value={'3'}>3</MenuItem>
        <MenuItem key={4} value={'4'}>4</MenuItem>
        <MenuItem key={5} value={'5'}>5</MenuItem>
        <MenuItem key={6} value={'6'}>6</MenuItem>
    </Select>)

    const inchSelect = () => (<Select name='ddlInches' disabled={isDisabled} error={!!errors?.heightInches} onChange={(e:any) => onChangeHeight(e)} value={inches} fullWidth>
        <MenuItem key={0} value={'0'}>0</MenuItem>
        <MenuItem key={1} value={'1'}>1</MenuItem>
        <MenuItem key={2} value={'2'}>2</MenuItem>
        <MenuItem key={3} value={'3'}>3</MenuItem>
        <MenuItem key={4} value={'4'}>4</MenuItem>
        <MenuItem key={5} value={'5'}>5</MenuItem>
        <MenuItem key={6} value={'6'}>6</MenuItem>
        <MenuItem key={7} value={'7'}>7</MenuItem>
        <MenuItem key={8} value={'8'}>8</MenuItem>
        <MenuItem key={9} value={'9'}>9</MenuItem>
        <MenuItem key={10} value={'10'}>10</MenuItem>
        <MenuItem key={11} value={'11'}>11</MenuItem>
    </Select>)
    
  return (
    <>
        <Grid container spacing={2}>
            <Input type="hidden" {...register("heightInches")} />
            {unit === 'fi' ? <>
                <Grid item xs={12} sm={1} sx={{mt:3, mr: -2}}>
                    <Typography variant='h6'>ft.</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                    {feetSelect()}
                </Grid>
                <Grid item xs={12} sm={1} sx={{mt:3, mr: -2}}>
                    <Typography variant='h6'>in.</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                    {inchSelect()}
                </Grid>
            </>
            :
                <Grid item xs={12} sm={6}>
                    <TextField
                        name='txtHeight'
                        value={height}
                        onChange={(e:any) => onChangeHeight(e)}
                        fullWidth
                        label="Height"
                        type='number'
                        error={!!errors?.heightInches}
                        /> 
                </Grid>
            }
            <Grid item xs={12} sm={6}>
                <Select 
                    name='ddlUnits'
                        value={unit} 
                        disabled={isDisabled}
                        onChange={(e:any) => 
                            onChangeUnits(e)}
                        error={!!errors?.heightInches} 
                        fullWidth>
                    {lookups.heights.map((_: Lookup) => (
                        <MenuItem key={_.id} value={_.value}>
                        {_.name}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <FormControl fullWidth error={!!errors?.heightInches}>
                <FormHelperText sx={{ paddingLeft: '1em'}}>{errors?.heightInches?.message}</FormHelperText>
            </FormControl>
        </Grid>
    </>
  )
}
