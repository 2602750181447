import { AppBar, Toolbar, Box, Typography, List, ListItem } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { useAppSelector } from '../hooks'
import SignedInMenu from './SignedInMenu'
import logo from '../images/logo.png'
import ButtonMenu from './ButtonMenu'

interface Props {
    darkMode: boolean
    handleThemeChange: () => void
  }

const rightLinks = [
{title: 'login', path: '/login'},
{title: 'register', path: '/register'}
]

export default function Header({darkMode, handleThemeChange}: Props) {
  const { user } = useAppSelector(state => state.account)

  return (
    <AppBar position='static' sx={{ mb: 4 }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box display={'flex'} alignItems='center' sx={{ display: { xs: 'none', md: 'flex' }}}>
            <Typography component={NavLink} to={'/'} sx={{ color: 'inherit', textDecoration: 'none'}}>
                <img src={logo} alt='logo' className='img-brand' />
            </Typography>
          </Box>

          {/* xs Menu */}
          <ButtonMenu />
          <Box display={'flex'} alignItems='center' sx={{ display: { xs: 'flex', md: 'none' } }}>
            <Typography variant='h6' component={NavLink} to={'/'}>
              <img src={logo} alt='logo' className='img-brand' />
            </Typography>
          </Box>
          {/* xs Menu */}

          <List sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
              <ListItem
                component={NavLink}
                sx={{color: 'inherit',fontWeight: 'bold', fontSize: 'x-large'}}
                to={'/calc'}
                key={'/calc'}
              >
                ALS Nutrition Calculator
              </ListItem>
          </List>
          <Box sx={{ display: { xs: 'none', md: 'flex' } , alignItems: 'center' }}>
            { user
              ? (<SignedInMenu />)
              : (<List sx={{ display: 'flex' }}>
                  {rightLinks.map(({title, path}) => (
                    <ListItem
                      component={NavLink}
                      sx={{color: 'inherit', fontWeight: 'bold'}}
                      to={path}
                      key={path}
                    >
                      {title.toUpperCase()}
                    </ListItem>
                  ))}
                </List>)
            }
          </Box>
        </Toolbar>
    </AppBar>
  )
}
