import { useEffect } from 'react';
import LoadingComponent from '../../app/components/LoadingComponent';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchCalcAsync } from '../calculator/calculatorSlice';
import PatientProfile from './PatientProfile'

export default function CreatePatient() {
    const dispatch = useAppDispatch()
    const { lookupsLoaded, lookups } = useAppSelector((state) => state.calculator);
    
    useEffect(() => {
        if (!lookupsLoaded) dispatch(fetchCalcAsync())
      }, [lookupsLoaded, dispatch]);

    if (!lookupsLoaded)
      return <LoadingComponent message='Initializing the form...' />

    return <PatientProfile lookups={lookups} title={"Create Profile"} />
}