import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import agent from '../../app/api/agent';
import LoadingComponent from '../../app/components/LoadingComponent';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Patient } from '../../app/models/patient'
import ALSCalculator from '../calculator/ALSCalculator';
import { fetchCalcAsync } from '../calculator/calculatorSlice';

export default function CreateLogEntry() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { lookupsLoaded, lookups } = useAppSelector((state) => state.calculator);
  const [patient, setPatient] = useState<Patient>();
  const [loading, setLoading] = useState(true);

  useEffect(() => { if (!lookupsLoaded) dispatch(fetchCalcAsync()) }, [lookupsLoaded, dispatch]);

  useEffect(() => {
    if (id) {
      agent.Patients.getpatient(id)
        .then((resp) => {
            setPatient(resp.patient);
            setLoading(false);
      })
    }
  }, [id])

  if (!lookupsLoaded || loading)
    return <LoadingComponent message="Loading form..." />

  // Prepare Log Object  
  var obj = { feet: 0, inches: 0, height: 0, weight: 0, age: 0, patientId: "", genderId: "", heightInches:0, weightLbs: 0 }
  if (patient)
  {
    obj.patientId = patient.id
    obj.genderId = patient.genderId.toUpperCase();
    obj.weightLbs = patient.weightLbs || 0
    const ht = parseInt(patient.heightInches);
    if (!isNaN(ht)) {
      obj.feet = Math.floor(ht / 12);
      obj.inches = ht % 12;
      obj.heightInches = ht;
    }

    const today = new Date();
    obj.age =
      today.getMonth() + 1 >= patient.birthMonth
        ? today.getFullYear() - patient.birthYear
        : today.getFullYear() - patient.birthYear - 1;
  }  

  return (
    <ALSCalculator lookups={lookups} preloadedValues={obj || null}/>
  )
}
