import axios, { AxiosError, AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { history } from '../..'
import { PaginatedResponse } from '../models/pagination'
import { store } from '../store'

const sleep = () => new Promise(resolve => setTimeout(resolve, 500))

axios.defaults.baseURL = 'https://alsapi.azurewebsites.net/api'

const responseBody = (response: AxiosResponse) => response.data

axios.interceptors.request.use((config: any) => {
    const token:string = store.getState().account.user?.token || ''
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config
})

axios.interceptors.response.use(async response => {
    await sleep()
    const pagination = response.headers['pagination']
    if (pagination) {
        response.data = new PaginatedResponse(response.data, JSON.parse(pagination))
        return response
    }
    
    return response
}, (error: AxiosError) => {
    const {data, status}: any = error.response!
    switch (status) {
        case 400:
            if (data.errors) {
                const modelStateErrors: string[] = []
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modelStateErrors.push(data.errors[key])
                    }
                }
                throw modelStateErrors.flat()
            }
            toast.error(data.title)
            break;
        case 401:
            toast.error(data.title || 'Unauthorized!')
            break;
        case 404:
            toast.error(data.title)
            break;
        case 500:
            history.push('/server-error')
            break;
        default:
            break;
    }
    return Promise.reject(error.response)
})

const requests = {
    get: (url: string, params?: URLSearchParams) => axios.get(url, {params}).then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody)
}

const Lookups = {
    fetchCalc: () => requests.get('Lookups')
}

const Account = {
    login: (values: any) => requests.post('Account/login', values),
    register: (values: any) => requests.post('Account/register', values),
    forgot: (values: any) => requests.post('Account/forgot-password', values),
    resetPwd: (values: any) => requests.post('Account/reset-password', values),
    currentUser: () => requests.get('account/curruser')
}

const Patients = {
    getpatients: () => requests.get('Patient/getpatients'),
    getpatient: (id: string) => requests.get(`Patient/getpatient/${id}`),
    addeditpatient: (values: any) => requests.post('Patient/patient', values),
    logentry: (values: any) => requests.post('PatientLogEntries/logentry', values),
    getcharts: (id: string) => requests.get(`Patient/getcharts?id=${id}`)
}

const agent = {
    Lookups,
    Account,
    Patients
}

export default agent