import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import FormHeightSelect from "../../app/components/FormHeightSelect";
import FormWeightSelect from "../../app/components/FormWeightSelect";
import ResultBox from "./ResultBox";
import { useNavigate } from "react-router-dom";
import FormSelectList from "../../app/components/FormSelectList";
import { yupResolver } from "@hookform/resolvers/yup";
import { alsCalculatorSchema } from "../../app/validations/AlsCalculatorValidation";
import FormText from "../../app/components/FormText";
import agent from "../../app/api/agent";
import { LoadingButton } from "@mui/lab";

interface Props {
  preloadedValues?: any
  lookups: any
}

export default function ALSCalculator({preloadedValues, lookups}: Props) {
  const [isSaving, setIsSaving] = useState(false)
  const navigate = useNavigate()
  const { control,
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: preloadedValues,
    mode: 'all',
    resolver: yupResolver(alsCalculatorSchema)
  });
  const [dataToPost, setDataToPost] = useState<FieldValues>({})
  const [showResult, setShowResult] = useState(false)
  const getWeightInKgs = (lbs: number) => (lbs * 0.4535924)
  const saveData = async () => {
    setIsSaving(true)
    await agent.Patients.logentry(dataToPost).then(() => navigate('/dash'))
  }
  const submitForm = (data: FieldValues) => {
    data.weightInKgs = getWeightInKgs(data.weightLbs)
    try {
      let objCalc: any = {};

      if (data.genderId === "FCED5CC5-5D71-4C91-B270-C91DCEADDE51") {
        objCalc.Constant = 66;
        objCalc.ConstantWeight = 6.23;
        objCalc.ConstantHeight = 12.7;
        objCalc.ConstantAge = 6.76;
        objCalc.ConstantBodyFatDWS = 16.2;
        objCalc.ConstantALSSexMultiplier = 1;
      } else {
        objCalc.Constant = 655;
        objCalc.ConstantWeight = 4.35;
        objCalc.ConstantHeight = 4.7;
        objCalc.ConstantAge = 4.7;
        objCalc.ConstantBodyFatDWS = 5.4;
        objCalc.ConstantALSSexMultiplier = 0;
      }
      objCalc.ConstantALSBulbarMultiplier = (data.siteOfOnset === 'Bulbar' ? 1 : 0)
      objCalc.ConstantALSBulbarMultiplier = 0;

      data.alsFrsScore =
        parseInt(data.speech) +
        parseInt(data.handwriting) +
        parseInt(data.dressing) +
        parseInt(data.bed) +
        parseInt(data.walk) +
        parseInt(data.dyspnea);
      data.alsFunctional = 55.6 * data.alsFrsScore - 168;
      data.bmr =
        objCalc.Constant +
        objCalc.ConstantWeight * data.weightLbs +
        objCalc.ConstantHeight * data.heightInches -
        objCalc.ConstantAge * parseInt(data.age);

      data.calories = Math.round(data.bmr + data.alsFunctional);
      data.liquid = Math.round((0.087 + 0.001151 * (data.bmr + data.alsFunctional)) * 10) / 10
      data.bmi = ((data.weightLbs /(data.heightInches * data.heightInches)) * 703).toFixed(1)

      // Body Fat
      data.dwsBF = data.bmi * 1.2 + 0.23 * parseInt(data.age) - objCalc.ConstantBodyFatDWS;
      const alsBF =
        1.73 -
        19.8 * objCalc.ConstantALSSexMultiplier +
        0.25 * getWeightInKgs(data.weightLbs) +
        0.952 * data.bmi -
        5.2 * objCalc.ConstantALSBulbarMultiplier;
      data.dwsFM = ((data.dwsBF / 100) * getWeightInKgs(data.weightLbs)).toFixed(1)
      data.dwsLBM = getWeightInKgs(data.weightLbs) - data.dwsFM
      data.alsFM = ((alsBF / 100) * getWeightInKgs(data.weightLbs)).toFixed(1)
      data.alsLBM = getWeightInKgs(data.weightLbs) - data.alsFM
      data.fatMass = data.dwsFM
      data.lbm = data.dwsLBM.toFixed(1)

      setDataToPost(data)
      setShowResult(true);
    } catch (error) {
      console.error("error", error);
    }
  }

  return (
    <Container component={Paper} maxWidth="lg" sx={{ alignItems: "center", p: 4 }} elevation={8}>
      <Box component="form" onSubmit={handleSubmit(submitForm)} noValidate sx={{ mt: 1, mx: 5 }}>
        <Typography variant="h3" align="center">
          ALS Nutrition Calculator
        </Typography>
        <hr />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} marginY={1}>
            <FormSelectList control={control} items={lookups?.genders} name='genderId' label='Gender' isDisabled={preloadedValues !== undefined}/>
          </Grid>
          <Grid item xs={12} md={6} marginY={1} >
            <FormText control={control} name="age" label="Age in years" disabled={preloadedValues !== undefined}/>
          </Grid>
          <Grid item xs={12} md={6} marginY={1}>
            <FormHeightSelect register={register} errors={errors} setValue={setValue} ft={preloadedValues?.feet || 0} ins={preloadedValues?.inches || 0} isDisabled={preloadedValues !== undefined}/>
          </Grid>
          <Grid item xs={12} md={6} marginY={1}>
            <FormWeightSelect register={register} errors={errors} setValue={setValue} lbs={preloadedValues?.weightLbs || 0} />
          </Grid>
          <Grid item xs={12} md={9} marginY={1}>
            <FormSelectList control={control} items={lookups?.speeches} name='speech' label='Speech' />
          </Grid>
          <Grid item xs={12} md={9} marginY={1}>
            <FormSelectList control={control} items={lookups?.handwritings} name='handwriting' label='Handwriting (with dominant hand prior to ALS onset)' />
          </Grid>
          <Grid item xs={12} md={9} marginY={1}>
            <FormSelectList control={control} items={lookups?.dressHygienes} name='dressing' label='Dressing and Hygiene' />
          </Grid>
          <Grid item xs={12} md={9} marginY={1}>
            <FormSelectList control={control} items={lookups?.bedTurns} name='bed' label='Turning in Bed/Adjusting Bed Clothes' />
          </Grid>
          <Grid item xs={12} md={9} marginY={1}>
            <FormSelectList control={control} items={lookups?.walkings} name='walk' label='Walking' />
          </Grid>
          <Grid item xs={12} md={9} marginY={1}>
            <FormSelectList control={control} items={lookups?.dyspneas} name='dyspnea' label='Dyspnea (Difficulty breathing)' />
          </Grid>
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Calculate
          </Button>
          {showResult ? <ResultBox results={dataToPost} /> : null}
          {preloadedValues !== undefined && showResult 
            ? <Grid item xs={12} marginY={1}>
                <LoadingButton loading={isSaving} onClick={saveData} variant='contained' fullWidth>
                  Save Entry & Back to Graphs
                </LoadingButton>
              </Grid>
            : null}
        </Grid>
      </Box>
    </Container>
  );
}
