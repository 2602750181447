import { Grid, Input, MenuItem, Select, TextField } from "@mui/material"
import { useState } from "react"
import { useAppSelector } from "../hooks"
import { Lookup } from "../models/lookups"

interface Props {
  label?: string
  lbs: number
  register:any
  setValue: any
  errors: any
}

export default function FormWeightSelect({register, setValue, errors, lbs, label = 'Weight'}: Props) {
  const {lookups} = useAppSelector(state => state.calculator)
  const [weight, setWeight] = useState(lbs)
  const [unit, setUnit] = useState('Lbs')
  const onChangeWeight = (val: number) =>{
    setWeight(val)
    setValue('weightLbs', unit === 'kg' ? Math.round(val*2.204623) : val)
  }
  const onChangeUnit = (val: string) => {
    setUnit(val)
    setValue('weightLbs', val === 'kg' ? Math.round(weight * 2.204623) : setValue('weightLbs', weight))
  }
  
  return (<>    
    <Grid container spacing={2}>
      <Input type="hidden" {...register("weightLbs")} />
      <Grid item xs={12} sm={6}>
        <TextField 
          id={`txt${label}`} 
          label={label}
          value={weight}
          type="number"
          onChange={(e:any) => onChangeWeight(e.target.value)}
          error={!!errors.weightLbs}
          helperText={errors?.weightLbs?.message?.toString()}
          fullWidth/>
      </Grid>      
      <Grid item xs={12} sm={6}>
        <Select name="ddlWeightUnits" {...register("weightunit")} value={unit} onChange={(e:any) => onChangeUnit(e.target.value)} fullWidth error={!!errors.weightLbs}>
        {lookups?.weights?.map((_: Lookup) => (
                        <MenuItem key={_.id} value={_.value}>
                        {_.name}
                        </MenuItem>
                    ))}          
        </Select>
      </Grid>
    </Grid>
    </>
  )
}
