import { Container, Paper, Typography, Button } from '@mui/material'
import { Link } from 'react-router-dom'

export default function ForgotPasswordConfirm() {
  return (
    <Container maxWidth={'lg'} sx={{ alignItems: 'center', p: 4 }} component={Paper} elevation={8}>
        <Typography variant='h3' sx={{ fontWeight: 700 }}>Password Reset</Typography>
        <hr />
        <Typography variant='body1' marginTop={3} paddingLeft={2}>Check your e-mail for further instructions on how to reset your password.</Typography>
        <Typography align='center' marginTop={5}>
            <Button variant='contained' component={Link} to="/login">Click here to Log Back In</Button>
        </Typography>
    </Container>
  )
}
