import { Button, Container, Paper, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export default function LogoutUser() {  
  return (
    <Container maxWidth={'lg'} sx={{ alignItems: 'center', p: 4 }} component={Paper} elevation={8}>
        <Typography variant='h3' sx={{ fontWeight: 700 }}>Logged Out</Typography>
        <hr />
        <Typography variant='body1' marginTop={3} paddingLeft={2}>You have successfully logged out of the application.</Typography>
        <Typography align='center' marginTop={5}>
            <Button variant='contained' component={Link} to="/login">Log Back In</Button>
        </Typography>
    </Container>
  )
}
