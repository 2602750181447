import * as yup from 'yup'

export const patientSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    birthMonth: yup.number().typeError('Birth Mont is required.').required('Birth Month is required').min(1, 'Birth Month is required').max(12, 'Birth Month is required'),
    birthYear: yup.number().typeError('Birth Year is required.').required('Birth Year is required.'),
    genderId: yup.string().required('Gender is required.'),
    siteOfOnSet: yup.string().required('Site of Disease Onset is required.'),
    heightInches: yup.number().typeError('Height is required.').required('Height is required.').min(1, 'Height is required').max(83, 'Height must be less than 84 in. or 213 cm.'),
    weightLbs: yup.number().typeError('Weight is required.').required('Weight is required.').min(1, 'Weight is required.').max(499,'Weight needs to be less than 500 lbs. or 227 kgs.')
})