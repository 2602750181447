import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import agent from "../../app/api/agent"

interface CalculatorState {
    lookups: any | null
    lookupsLoaded: boolean
    status: string
}

const initialState: CalculatorState = {
    lookups: null,
    lookupsLoaded: false,
    status: 'idle'
}
export const fetchCalcAsync = createAsyncThunk<any>(
    'lookups/fetchCalc',
    async (_, thunkAPI) => {
        try {
            const calc =  await agent.Lookups.fetchCalc()
            return calc
        } catch (error:any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)
export const calculatorSlice = createSlice({
    name: 'lookups',
    initialState,
    reducers: {},
    extraReducers: (builder => {
        builder.addCase(fetchCalcAsync.pending, (state) => {
            state.status = 'pending'
        })
        builder.addCase(fetchCalcAsync.fulfilled, (state, action) => {
            state.lookups = action.payload
            state.status = 'idle'
            state.lookupsLoaded = true
        })
        builder.addCase(fetchCalcAsync.rejected, (state, action) => {
            state.status = 'idle'
            state.lookups = null
            state.lookupsLoaded = false
            throw action.payload            
        })
    })
})

export default calculatorSlice.reducer