import { Navigate, useLocation } from 'react-router-dom'
import { useAppSelector } from '../hooks'

interface Props {
    component: JSX.Element
}

export default function PrivateRoute({component}: Props) {
  const location = useLocation()
  const {user} = useAppSelector(state => state.account)

  if (!user)
    return <Navigate to="/login" state={{ from: location}} />
  return component
}