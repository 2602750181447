import * as yup from 'yup'

export const alsCalculatorSchema = yup.object().shape({
    genderId: yup.string().required('Gender is required.'),
    age: yup.number().typeError('Age is required.').required('Age is required.').min(1, 'Age is required.').max(119, 'Age must be less than 120'),
    speech: yup.number().typeError('Speech is required.').required('Speech is required.'),
    handwriting: yup.number().typeError('Handwriting (with dominant hand prior to ALS onset) is required.').required('Handwriting (with dominant hand prior to ALS onset) is required.'),
    dressing: yup.number().typeError('Dressing and Hygiene is required.').required('Dressing and Hygiene is required.'),
    bed: yup.number().typeError('Turning in Bed/Adjusting Bed Clothes is required.').required('Turning in Bed/Adjusting Bed Clothes is required.'),
    walk: yup.number().typeError('Walking is required.').required('Walking is required.'),
    dyspnea: yup.number().typeError('Dyspnea is required.').required('Dyspnea is required.'),
    heightInches: yup.number().typeError('Height is required.').required('Height is required.').min(1, 'Height is required').max(83, 'Height must be less than 84 in. or 213 cm.'),
    weightLbs: yup.number().typeError('Weight is required.').required('Weight is required.').min(1, 'Weight is required.').max(499,'Weight needs to be less than 500 lbs. or 227 kgs.')
})