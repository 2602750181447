import { ThemeProvider } from '@emotion/react';
import { CssBaseline, Container, createTheme } from '@mui/material';
import { useCallback, useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import '../styles/App.css';
import Header from './Header';
import HomePage from './HomePage';
import RegisterUser from '../../features/account/RegisterUser'
import LoginUser from '../../features/account/LoginUser';
import PrivateRoute from './PrivateRoute';
import Dashboard from '../../features/patients/Dashboard';
import { useAppDispatch } from '../hooks';
import { fetchCurrentUser } from '../../features/account/accountSlice';
import LogoutUser from '../../features/account/LogoutUser';
import CreatePatient from '../../features/patients/CreatePatient';
import EditPatient from '../../features/patients/EditPatient';
import CreateLogEntry from '../../features/patients/CreateLogEntry';
import LoadingComponent from '../components/LoadingComponent';
import PublicCalculator from '../../features/calculator/PublicCalculator';
import ForgotPassword from '../../features/account/ForgotPassword';
import ForgotPasswordConfirm from '../../features/account/ForgotPasswordCofirm';
import ResetPassword from '../../features/account/ResetPassword';
import Unavailable from './Unavailable';

function App() {
  const unavailable = false
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  //const [darkMode, setDarkMode] = useState(localStorage.getItem('lastMode') === 'true' ? true : false)
  const [darkMode, setDarkMode] = useState(false)
  const handleThemeChange = () => {
    localStorage.setItem('lastMode', (!darkMode).toString())
    setDarkMode(!darkMode)
  }
  const theme = darkMode ? createTheme({
    palette: {
      mode: 'dark'
    }
  }) : createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#152c5e'
      }
    }
  })
  
  const initApp = useCallback(async () => {
    try {
      await dispatch(fetchCurrentUser())
    } catch (error) {
      console.log('error', error)
    }
  }, [dispatch])
  
  useEffect(() => { initApp().then(() => setLoading(false)) }, [initApp])
    
  if (loading) return <LoadingComponent message="Initializing your display..." />    


  return (
    <ThemeProvider theme={theme}>
      <ToastContainer position='bottom-right' hideProgressBar theme='colored' />
      <CssBaseline />
      {unavailable ? <Unavailable />
       : <>       
          <Header darkMode={darkMode} handleThemeChange={handleThemeChange} />
          <Container sx={{ mb: 4 }}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path='/calc' element={<PublicCalculator />} />
              <Route path='/register' element={<RegisterUser />} />
              <Route path='/login' element={<LoginUser />} />
              <Route path='/logout' element={<LogoutUser />} />
              <Route path='/forgot' element={<ForgotPassword />} />
              <Route path='/forgotconfirm' element={<ForgotPasswordConfirm />} />
              <Route path='/reset/:id' element={<ResetPassword />} />
              <Route path='/patient' element={<PrivateRoute component={<CreatePatient />} />} />
              <Route path='/patient/edit' element={<PrivateRoute component={<EditPatient />} />} />          
              <Route path='/log/:id' element={<PrivateRoute component={<CreateLogEntry />} />} />          
              <Route path='/dash' element={<PrivateRoute component={<Dashboard />} />} />
            </Routes>
          </Container>
        </>
      }
    </ThemeProvider>
  );
}

export default App;
