import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { signOut } from '../../features/account/accountSlice'
import { useAppDispatch, useAppSelector } from '../hooks'
import MenuIcon from '@mui/icons-material/Menu'

export default function ButtonMenu() {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(state => state.account)
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElNav(event.currentTarget)
  const handleCloseNavMenu = () => setAnchorElNav(null)
  
  
  return (
    <>
    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >
        {user ? <div>
          <MenuItem key={'home'} onClick={handleCloseNavMenu}>
            <Typography textAlign="center" sx={{ color: 'inherit', textDecoration: 'none' }} component={NavLink} to='/dash'>{'Home'}</Typography>
          </MenuItem>
          <MenuItem key={'edit'} onClick={handleCloseNavMenu}>
            <Typography textAlign="center" sx={{ color: 'inherit', textDecoration: 'none' }} component={NavLink} to='/patient/edit'>{'Edit Profile'}</Typography>
          </MenuItem>
          <MenuItem key={'logout'} onClick={handleCloseNavMenu}>
            <Typography textAlign="center" sx={{ color: 'inherit', textDecoration: 'none' }} onClick={() => dispatch(signOut())} component={NavLink} to='/logout'>{'Logout'}</Typography>
          </MenuItem>
        </div>
          : <div>
            <MenuItem key={'home'} onClick={handleCloseNavMenu}>
              <Typography textAlign="center" sx={{ color: 'inherit', textDecoration: 'none' }} component={NavLink} to='/'>{'Home'}</Typography>
            </MenuItem>
            <MenuItem key={'calc'} onClick={handleCloseNavMenu}>
              <Typography textAlign="center" sx={{ color: 'inherit', textDecoration: 'none' }} component={NavLink} to={'/calc'}>Calculator</Typography>
            </MenuItem>
            <MenuItem key={'login'} onClick={handleCloseNavMenu}>
              <Typography textAlign="center" sx={{ color: 'inherit', textDecoration: 'none' }} component={NavLink} to={'/login'}>Login</Typography>
            </MenuItem>
            <MenuItem key={'register'} onClick={handleCloseNavMenu}>
              <Typography textAlign="center" sx={{ color: 'inherit', textDecoration: 'none' }} component={NavLink} to={'/register'}>Register</Typography>
            </MenuItem>
          </div>}

      </Menu>
    </Box>
  </>
  )
}
