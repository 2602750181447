import { LoadingButton } from '@mui/lab'
import { Container, Paper, Avatar, Typography, Box, TextField, Grid } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link } from 'react-router-dom'
import { FieldValues, useForm } from 'react-hook-form';
import { useAppDispatch } from '../../app/hooks';
import { forgotPassword } from './accountSlice';
import { useState } from 'react';

export default function ForgotPassword() {
    const dispatch = useAppDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { register, handleSubmit, formState: { errors, isValid } } = useForm({
        mode: 'all'
    });    
    const submitForm = async (data: FieldValues) => {
        setIsSubmitting(true)
        dispatch(forgotPassword(data))
    }

  return (
    <Container component={Paper} maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 4 }} elevation={8}>
    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <LockOutlinedIcon />
    </Avatar>
    <Typography component="h1" variant="h5">
        Forgot Password
    </Typography>
    <Box component="form" onSubmit={handleSubmit(submitForm)} noValidate sx={{ mt: 1 }}>
        <TextField
            margin="normal"
            fullWidth
            label="Email"
            autoFocus
            {...register('email', { required: 'Email is required' })}
            error={!!errors.email}
            helperText={errors?.email?.message?.toString()}
        />
        <Typography component="p">
            Please enter your email and click on submit.
        </Typography>
        <LoadingButton
            loading={isSubmitting}
            disabled={!isValid}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
        >
            Submit
        </LoadingButton>
        <Grid container>
            <Grid item>
                <Link to='/register'>
                    {"Don't have an account? Sign Up"}
                </Link>
            </Grid>
        </Grid>
    </Box>
</Container>
  )
}
