import * as yup from 'yup'

export const resetPasswordSchema = yup.object().shape({
    email: yup.string().required('Email is required.').email('Email must be in valid format.'),
    password: yup.string().required('Please Enter your password')
                .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
                "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                ),
    confirm: yup.string().test('passwords-match', 'Passwords must match', function(value) {
        return this.parent.password === value
    })
})