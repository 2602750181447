import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { useController, UseControllerProps } from 'react-hook-form'

interface Props extends UseControllerProps {
    label: string
    isRequired?: boolean | false
    isDisabled?: boolean | false
    items: string[]
}

export default function FormSelectList(props: Props) {
  const {fieldState, field} = useController({...props, defaultValue: ''})
  return (
    <FormControl fullWidth error={!!fieldState.error}>
        <InputLabel>{props.label}</InputLabel>
        <Select value={field.value} label={props.label} onChange={field.onChange} disabled={props.isDisabled} >
            {props.items.map((item: any, index) => (
                <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
            ))}
        </Select>
        <FormHelperText>{fieldState.error?.message}</FormHelperText>
    </FormControl>
  )
}
