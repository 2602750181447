import { LoadingButton } from '@mui/lab'
import { Container, Paper, Avatar, Typography, Box, TextField } from '@mui/material'
import { useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { FieldValues, useForm } from 'react-hook-form'
import { resetPasswordSchema } from '../../app/validations/ResetPasswordValidation'
import { useAppDispatch } from '../../app/hooks';
import { resetPassword } from './accountSlice';

export default function ResetPassword() {
    const dispatch = useAppDispatch()
    const { id } = useParams()
    const { register, handleSubmit, formState: { isSubmitting, errors, isValid } } = useForm({
        mode: 'all',
        resolver: yupResolver(resetPasswordSchema)
    })
    const submitForm = async (data: FieldValues) => {
        await dispatch(resetPassword({...data, token: id}))
    }
    
  return (
    <Container
    component={Paper}
    maxWidth="sm"
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      p: 4,
    }}
    elevation={8}
  >
    <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
      <LockOutlinedIcon />
    </Avatar>
    <Typography component="h1" variant="h5">
      Reset Password
    </Typography>
    <Box
      component="form"
      onSubmit={handleSubmit(submitForm)}
      noValidate
      sx={{ mt: 1 }}
    >
      <TextField
        margin="normal"
        fullWidth
        label="Email address"
        {...register("email")}
        error={!!errors.email}
        helperText={errors?.email?.message?.toString()}
      />      
      <TextField
        margin="normal"
        fullWidth
        label="New Password"
        type="password"
        {...register("password")}
        error={!!errors.password}
        helperText={errors?.password?.message?.toString()}
      />
      <TextField
        margin="normal"
        fullWidth
        label="Confirm Password"
        type="password"
        {...register("confirm")}
        error={!!errors.confirm}
        helperText={errors?.confirm?.message?.toString()}
      />
      <LoadingButton
        disabled={!isValid}
        loading={isSubmitting}
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Submit
      </LoadingButton>
    </Box>
  </Container>
  )
}
