import { Grid } from "@mui/material";
import LineChart from "../../app/components/charts/LineChart";
import { useAppSelector } from "../../app/hooks";

export default function PatientCharts() {
  const { charts } = useAppSelector((state) => state.patients)
  if (charts === null) return <></>

  const calorieData = {
    labels: charts.logDates,
    datasets: [
      {
        label: 'Calories (kcal/d)',
        data: charts.calories,
        borderColor: 'rgba(255, 159, 64, 1)',
        backgroundColor: 'rgba(255, 159, 64, 0.2)'
      }
    ],
  }

  const weightData = {
    labels: charts.logDates,
    datasets: [
      {
        label: 'Weight (lbs)',
        data: charts.weights,
        borderColor: 'rgba(0, 0, 255, 1)',
        backgroundColor: 'rgba(0, 0, 255, 0.2)'
      },
      {
        label: 'Weight (kgs))',
        data: charts.weightkgs,
        borderColor: 'rgba(50, 205, 50, 1)',
        backgroundColor: 'rgba(50, 205, 50, 0.2)'
      }
    ],
  }
  
  const bmiData = {
    labels: charts.logDates,
    datasets: [
      {
        label: 'BMI',
        data: charts.bmis,
        borderColor: 'rgba(255, 188, 0, 1)',
        backgroundColor: 'rgba(255, 188, 0, 0.2)'
      }
    ],
  }
  const alsfrsData = {
    labels: charts.logDates,
    datasets: [
      {
        label: 'ALS-FRS-6',
        data: charts.alsfrs,
        borderColor: 'rgba(50, 205, 50, 1)',
        backgroundColor: 'rgba(50, 205, 50, 0.2)'
      }
    ],
  }
  const fluidsData = {
    labels: charts.logDates,
    datasets: [
      {
        label: 'Fluids (L/d)',
        data: charts.liquids,
        borderColor: 'rgba(0, 150, 255, 1)',
        backgroundColor: 'rgba(0, 150, 255, 0.2)'
      }
    ],
  }
  const fatMassData = {
    labels: charts.logDates,
    datasets: [
      {
        label: 'ALS',
        data: charts.alsFM,
        borderColor: 'rgba(0, 0, 255, 1)',
        backgroundColor: 'rgba(0, 0, 255, 0.2)'
      },
      {
        label: 'Deurenburg',
        data: charts.dwsFM,
        borderColor: 'rgba(255, 0, 5, 1)',
        backgroundColor: 'rgba(255, 0, 5, 0.2)'
      }
    ],
  }
  const leanBodyMassData = {
    labels: charts.logDates,
    datasets: [
      {
        label: 'ALS',
        data: charts.alsLBM,
        borderColor: 'rgba(0, 0, 255, 1)',
        backgroundColor: 'rgba(0, 0, 255, 0.2)'
      },
      {
        label: 'Deurenburg',
        data: charts.dwsLBM,
        borderColor: 'rgba(255, 0, 5, 1)',
        backgroundColor: 'rgba(255, 0, 5, 0.2)'
      }
    ],
  }

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} md={6} lg={4}>
        <LineChart data={alsfrsData} title={'ALS-FRS Score'} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <LineChart data={calorieData} title={'Calories'} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <LineChart data={fluidsData} title={'Fluids'} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <LineChart data={weightData} title={'Weight'} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <LineChart data={fatMassData} title={'Fat Mass'} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <LineChart data={leanBodyMassData} title={'Lean Body Mass'} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <LineChart data={bmiData} title={'Body Mass Index'} />
      </Grid>
    </Grid>
  );
}
