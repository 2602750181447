import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit"
import { FieldValues } from "react-hook-form"
import agent from "../../app/api/agent"
import { Patient } from "../../app/models/patient"
import { history } from '../..'


interface PatientsState {
    list: Patient[]
    charts: any | null
    patient: Patient | null
    patientsLoaded: boolean
    status: string
}
const initialState: PatientsState = {
    list: [],
    charts: null,
    patient: null,
    patientsLoaded: false,
    status: 'idle'
}
export const fetchPatientsAsync = createAsyncThunk<any>(
    'patients/fetchPatients',
    async (_, thnukAPI) => {
        try {
            const patients = await agent.Patients.getpatients()
            return patients
        } catch (error: any) {            
            return thnukAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const addeditPatientAsync = createAsyncThunk<Patient, FieldValues>(
    'patients/addeditPatient',
    async (data, thunkAPI) => {
        try {
            if (data.illnessOnSetMonth === "")
                data.illnessOnSetMonth = null
            if (data.illnessOnSetYear === "")
                data.illnessOnSetYear = null
            
            const patient = await agent.Patients.addeditpatient(data)
            history.push('/dash')
            return patient
        } catch (error: any) {
            console.log('error', error)
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchPatientAsync = createAsyncThunk<Patient, string>(
    'patients/fetchPatient',
    async (id, thunkAPI) => {
        try {
            
            const patient = await agent.Patients.getpatient(id)
            return patient
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }        
    }
)
export const fetchPatientChartsAsync = createAsyncThunk<any, string>(
    'patients/fetchPatientCharts',
    async (id, thunkAPI) => {
        try {
            const charts = await agent.Patients.getcharts(id)
            return charts
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }        
    }
)

export const patientsSlice = createSlice({
    name: 'patients',
    initialState,
    reducers: {
        fetchPatient: (state, action) => {
            if (!action.payload) {
                state.patient = null
                return
            }
            
            const ht = parseInt(action.payload.heightInches)            
            const feet = Math.floor(ht/12)
            const inches = ht%12
            const height = ht
            state.patient = {...action.payload, 
                            genderId: action.payload.genderId.toUpperCase(),
                            feet,
                            inches,
                            height
            }
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchPatientsAsync.fulfilled, (state, action) => {
            state.list = action.payload.patients
            state.patientsLoaded = true
            state.status = 'idle'
        })
        builder.addCase(fetchPatientsAsync.rejected, (state, action) => {
            state.status = 'idle'
            state.list = []
            state.patientsLoaded = false
            throw action.payload
        })
        builder.addCase(fetchPatientChartsAsync.fulfilled, (state, action) => {
            state.status = 'idle'
            state.charts = action.payload
        })        
        builder.addCase(fetchPatientChartsAsync.rejected, (state, action) => {
            state.status = 'idle'
            state.charts = []
            throw action.payload
        })

        builder.addMatcher(isAnyOf(fetchPatientsAsync.pending, fetchPatientChartsAsync.pending), (state => {
            state.status = 'pending'
        }))        
    })
})

export const { fetchPatient } = patientsSlice.actions

export default patientsSlice.reducer