import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { useController, UseControllerProps } from 'react-hook-form'

interface Props extends UseControllerProps {  
  label: string
  name: string
}

export default function MonthSelect(props: Props) {
  const {fieldState, field} = useController({...props, defaultValue: ''})
    const options = [
        { description: "1", value: "1", name: "January"},
        { description: "2", value: "2", name: "February"},
        { description: "3", value: "3", name: "March"},
        { description: "4", value: "4", name: "April"},
        { description: "5", value: "5", name: "May"},
        { description: "6", value: "6", name: "June"},
        { description: "7", value: "7", name: "July"},
        { description: "8", value: "8", name: "August"},
        { description: "9", value: "9", name: "September"},
        { description: "10", value: "10", name: "October"},
        { description: "11", value: "11", name: "November"},
        { description: "12", value: "12", name: "December"}
    ]
  return (
    <FormControl fullWidth error={!!fieldState.error}>
      <InputLabel>{props.label}</InputLabel>
      <Select
          value={field.value || ""}
          label={props.label}
          onChange={field.onChange}
          >
            <MenuItem key={'null'} value={""}></MenuItem>
            {options.map((v) => <MenuItem key={v.value} value={v.value}>{v.name}</MenuItem>)}
      </Select>
      <FormHelperText>{fieldState.error?.message}</FormHelperText>
  </FormControl>    
  )
}
