import { Button, Fade, IconButton, Menu, MenuItem, SvgIcon, SvgIconProps } from '@mui/material'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { signOut } from '../../features/account/accountSlice'
import { useAppDispatch, useAppSelector } from '../hooks'

export default function SignedInMenu() {
    const dispatch = useAppDispatch()
    const {user} = useAppSelector(state => state.account)
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => (setAnchorEl(null))
    function HomeIcon(props: SvgIconProps) {
        return (
          <SvgIcon {...props}>
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
          </SvgIcon>
        );
      }    
  return (
    <>
        <IconButton component={Link} to="/dash">
            <HomeIcon fontSize='large' sx={{color: 'white'}} />
        </IconButton>
        <Button 
            color='inherit'
            onClick={handleClick}
            sx={{typography: 'h6'}}
        >
            {user?.displayName}
        </Button>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
        >
            <MenuItem component={Link} to='/patient/edit' onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={() => {
                dispatch(signOut())
            }}>Logout</MenuItem>
        </Menu>
    </>
  )
}