import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Paper } from '@mui/material';
import { LoadingButton } from '@mui/lab'
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerUserSchema } from "../../app/validations/RegisterUserValidation"
import { registerUser } from './accountSlice';
import { useAppDispatch } from '../../app/hooks';

export default function RegisterUser() {
    const dispatch = useAppDispatch()
    const { register, handleSubmit,  
            formState: { errors, isValid, isSubmitting } } 
        = useForm({
        mode: 'all',
        resolver: yupResolver(registerUserSchema)
    })
    
    return (
      <Container
        component={Paper}
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 4,
        }}
        elevation={8}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(async (data) => {
            data.username = data.email.substr(0, data.email.indexOf("@"));
            data.displayName = data.email.substr(0, data.email.indexOf("@"));
            await dispatch(registerUser(data))
          })}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            fullWidth
            label="Email address"
            {...register("email")}
            error={!!errors.email}
            helperText={errors?.email?.message?.toString()}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Password"
            type="password"
            {...register("password")}
            error={!!errors.password}
            helperText={errors?.password?.message?.toString()}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Confirm Password"
            type="password"
            {...register("confirm")}
            error={!!errors.confirm}
            helperText={errors?.confirm?.message?.toString()}
          />
          <LoadingButton
            disabled={!isValid}
            loading={isSubmitting}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Register
          </LoadingButton>
          <Grid container>
            <Grid item>
              <Link to="/login">{"Already have an account? Sign In"}</Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
}