import { Button, Container, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import LoadingComponent from "../../app/components/LoadingComponent";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { signOut } from "../account/accountSlice";
import PatientCharts from "./PatientCharts";
import { fetchPatient, fetchPatientChartsAsync, fetchPatientsAsync } from "./patientsSlice";

export default function Dashboard() {
  const dispatch = useAppDispatch();
  const { list, status } = useAppSelector((state) => state.patients);
  
  useEffect(() => {
    dispatch(fetchPatientsAsync()).then((resp) => {
      dispatch(fetchPatient(resp.payload.patients[0]))
      dispatch(fetchPatientChartsAsync(resp.payload.patients[0]?.id))
    })
  }, [dispatch]);

  if (status !== "idle")
    return <LoadingComponent message="Initializing your display..." />;

  // If no patient exists then show button to create one
  if (list.length === 0)
    return (
      <Typography align="center">
        <Button
          component={Link}
          to="/patient"
          variant="contained"
          color="success"
          sx={{ fontSize: "2em" }}
        >
          Click Here to Start
        </Button>
      </Typography>
    )

  // If a patient exists show buttons and charts
  return (
    <Container maxWidth={"lg"} sx={{ alignItems: "center", p: 4 }}>
      <Grid container columnSpacing={2}>
        {/* New Data Button */}
        <Grid item xs={12} sm={4} marginY={1}>
          <Typography align="center">
            <Button
              component={Link}
              to={`/log/${list[0].id}`}
              variant="contained"
              color="success"
              fullWidth
              sx={{ fontSize: "1em" }}
            >
              Click Here to Start
            </Button>
          </Typography>
        </Grid>

        {/* Edit Profile Button */}
        <Grid item xs={12} sm={4} marginY={1}>
          <Typography align="center">
            <Button
              component={Link}
              to={`/patient/edit`}
              variant="contained"
              fullWidth
              sx={{ fontSize: "1em" }}
            >
              Edit Profile
            </Button>
          </Typography>
        </Grid>

        {/* Log Out Button */}
        <Grid item xs={12} sm={4} marginY={1}>
          <Typography align="center">
            <Button
              onClick={() => dispatch(signOut())}
              variant="contained"
              fullWidth
              sx={{ fontSize: "1em" }}
            >
              Save Data & Logout
            </Button>
          </Typography>          
        </Grid>

        {/* Charts */}
        <Grid item xs={12} marginY={1}>
          <PatientCharts />
        </Grid>
      </Grid>
    </Container>
  )
}
