import { Box, Button, Paper } from '@mui/material';
import { Container } from '@mui/system';
import { useEffect } from 'react'
import { Link } from 'react-router-dom';
import LoadingComponent from '../../app/components/LoadingComponent';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ALSCalculator from './ALSCalculator';
import { fetchCalcAsync } from './calculatorSlice';

export default function PublicCalculator() {
    const dispatch = useAppDispatch();
    const { lookupsLoaded, lookups } = useAppSelector((state) => state.calculator);
    
    useEffect(() => { if (!lookupsLoaded) dispatch(fetchCalcAsync()) }, [lookupsLoaded, dispatch]);       

    if (!lookupsLoaded)
        return <LoadingComponent message="Loading form..." />
        
    return <Container>
        <ALSCalculator lookups={lookups} />
        <Box component={Paper} elevation={8} marginY={2} paddingY={2} paddingX={5}>
            <Button component={Link} to="/register" variant="contained" fullWidth sx={{ my: 1 }}>
                Register to Save Results
            </Button>
            <Button component={Link} to="/login" variant="contained" fullWidth sx={{ my: 1 }}>
                Go to Existing Account
            </Button>
        </Box>
    </Container>
}
