import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit"
import { FieldValues } from "react-hook-form"
import { toast } from "react-toastify"
import agent from "../../app/api/agent"
import { User } from "../../app/models/user"
import { history } from '../..'

interface AccountState {
    user: User | null
}

const initialState: AccountState = {
    user: null
}
export const registerUser = createAsyncThunk<User, FieldValues>(
    'account/registerUser',
    async (data, thunkAPI) => {
        try {
            const userDto = await agent.Account.register(data)
            localStorage.setItem('user', JSON.stringify(userDto))
            toast.success("Registration successful - you are now logged in")
            return userDto
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)
export const signInUser = createAsyncThunk<User, FieldValues>(
    'account/signInUser',
    async (data, thunkAPI) => {
        try {
            const userDto = await agent.Account.login(data)
            localStorage.setItem('user', JSON.stringify(userDto))
            return userDto
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)

export const fetchCurrentUser = createAsyncThunk<User>(
    'account/fetchCurrentUser',
    async (_, thunkAPI) => {
        thunkAPI.dispatch(setUser(JSON.parse(localStorage.getItem('user')!)))
        try {
            const userDto = await agent.Account.currentUser()
            return userDto
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    },
    {
        condition: () => {
            if (!localStorage.getItem('user')) return false
        }
    }
)

export const forgotPassword = createAsyncThunk<void, FieldValues>(
    'account/forgotPassword',
    async (data, thunkAPI) =>{
        try {
            const reset = await agent.Account.forgot(data)
            return reset
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)

export const resetPassword = createAsyncThunk<void, FieldValues>(
    'account/resetPassword',
    async (data, thunkAPI) => {
        try{
            const userDto = await agent.Account.resetPwd(data)            
            return userDto
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }        
    }
)

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        signOut: (state) => {
            state.user = null;
            localStorage.removeItem('user');
            history.push('/');
        },
        setUser: (state, action) => {
            let claims = JSON.parse(atob(action.payload.token.split('.')[1]));
            let roles = claims['role'];
            state.user = {...action.payload, roles: typeof(roles) === 'string' ? [roles] : roles};
        }        
    },
    extraReducers: (builder => {
        builder.addCase(fetchCurrentUser.rejected, (state) => {
            state.user = null;
            localStorage.removeItem('user');
            toast.error('Session expired - please login again');
            history.push('/login');
        })        
        builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
            let claims = JSON.parse(atob(action.payload.token.split('.')[1]))
            let roles = claims['role']
            state.user = {...action.payload, roles: (typeof(roles) === 'string' ? [roles] : roles)}
        })
        builder.addCase(forgotPassword.fulfilled, (state, action) => {
            history.push('/forgotconfirm')
        })
        builder.addCase(resetPassword.fulfilled, (state, action) => {
            toast.success('Password reset please login with your new password.')
            history.push('/login')
        })        
        builder.addMatcher(isAnyOf(registerUser.fulfilled, signInUser.fulfilled), (state, action) => {
            let claims = JSON.parse(atob(action.payload.token.split('.')[1]))
            let roles = claims['role']
            state.user = {...action.payload, roles: (typeof(roles) === 'string' ? [roles] : roles)}
            history.push('/dash')
        })
        builder.addMatcher(isAnyOf(signInUser.rejected), (state, action) => {
            throw action.payload;
        })
    })
})

export const { signOut, setUser } = accountSlice.actions

export default accountSlice.reducer