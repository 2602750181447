import { Box, FormControl, Typography } from '@mui/material'

interface Props {
    results: any
}

export default function ResultBox({results}: Props) {
  return (    
    <Box component={FormControl} fullWidth sx={{ border: 'solid', backgroundColor: '#f2edbb', padding: '1em' }}>
        <Typography>            
            Your current weight is {results.weightLbs} lbs. or {(results.weightInKgs).toFixed(1)} kg.
        </Typography>
        <Typography>            
            Your Body Mass Index (BMI) is {results.bmi}
        </Typography>
        <Typography>            
            Your Fat Mass is {results.fatMass} kg.
        </Typography>
        <Typography>            
            Your Lean Body Mass is {results.lbm} kg.
        </Typography>
        <br />
        <Typography>            
            Your ALSFRS-6 score is {results.alsFrsScore}
        </Typography>
        <br />
        <Typography >
            Your recommended calorie intake is {results.calories} calories per day
        </Typography>
        <Typography>
            Your recommended liquid intake is {results.liquid} liters per day
        </Typography>
    </Box>
  )
}
