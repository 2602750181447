import { Box, Button, Container, FormControl, Grid, Paper, TextField, Typography } from "@mui/material"
import { FieldValues, useForm } from "react-hook-form"
import { useAppDispatch } from "../../app/hooks"
import MonthSelect from '../../app/components/MonthSelect'
import YearSelect from '../../app/components/YearSelect'
import FormWeightSelect from '../../app/components/FormWeightSelect'
import FormHeightSelect from '../../app/components/FormHeightSelect'
import { addeditPatientAsync } from './patientsSlice'
import FormSelectList from "../../app/components/FormSelectList"
import { yupResolver } from '@hookform/resolvers/yup'
import { patientSchema } from "../../app/validations/PatientValidation"
import { Link } from "react-router-dom"
import { LoadingButton } from "@mui/lab"
import OnSetYearSelect from "../../app/components/OnSetYearSelect"

interface Props {
  title: string
  preloadedValues?: any
  lookups: any
}

export default function PatientProfile({preloadedValues, lookups, title}: Props) {  
  const dispatch = useAppDispatch()
  const { register, control, handleSubmit, setValue, formState: { errors, isSubmitting }} = useForm({ defaultValues: preloadedValues,
                                                                              mode: 'all',
                                                                              resolver: yupResolver(patientSchema)
                                                                            })
  
  const submitForm = async (data: FieldValues) => {
    data.userId = 'als'    
    await dispatch(addeditPatientAsync(data))
  }
  
  return (
    <Container maxWidth="lg" component={Paper} elevation={8} sx={{ alignItems: "center", p: 4 }}>
      <Typography align="left" variant="h3" fontWeight={500}>
        {title}
      </Typography>      
      <hr />
      <Box component='form' onSubmit={handleSubmit(submitForm)} noValidate sx={{ mt: 1, mx: 5 }} >
        <Typography align="left" variant="h4" fontWeight={500}>
          Demographic Information
        </Typography>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6} marginY={1}>
            <FormControl fullWidth>
              <TextField
                id="txtFirstName"
                {...register("firstName", { required: "First name is required" })}
                label="First Name"
                error={!!errors["firstName"]}
                helperText={errors["firstName"]?.message?.toString()}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} marginY={1}>
            <FormControl fullWidth>
              <TextField
                id="txtLastName"
                {...register("lastName", { required: "Last name is required" })}
                label="Last Name"
                error={!!errors["lastName"]}
                helperText={errors["lastName"]?.message?.toString()}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} marginY={1}>
            <MonthSelect control={control} label={'Birth Month'} name="birthMonth" />
          </Grid>
          <Grid item xs={12} sm={4} marginY={1}>
            <YearSelect control={control} label={"Birth Year"} name="birthYear" />
          </Grid>
          <Grid item xs={12} sm={4} marginY={1}>
            <FormSelectList control={control} items={lookups?.genders} name='genderId' label='Gender' />
          </Grid>
          <Grid item xs={12} marginY={1}>
            <FormWeightSelect register={register} errors={errors} setValue={setValue} lbs={preloadedValues?.weightLbs || 0}/>
          </Grid>
          <Grid item xs={12} marginY={1}>
            <FormHeightSelect register={register} errors={errors} setValue={setValue} ft={preloadedValues?.feet || 0} ins={preloadedValues?.inches || 0} />
          </Grid>
        </Grid>
        <hr />
        <Typography align="left" variant="h4" fontWeight={500}>
          ALS/MND Information
        </Typography>
        <Grid container columnSpacing={2} >          
          <Grid item xs={12} sm={6} marginY={1}>
            <MonthSelect control={control} label={'Month of Disease Onset'} name="illnessOnSetMonth" />
          </Grid>
          <Grid item xs={'auto'} />
          <Grid item xs={12} sm={6} marginY={1}>
            <OnSetYearSelect control={control} label={"Year of Disease Onset"} name="illnessOnSetYear"  />
          </Grid>
          <Grid item xs={'auto'} />
          <Grid item xs={12} sm={6} marginY={1}>
            <FormSelectList control={control} items={lookups?.siteOnSet} name='siteOfOnSet' label='Site of Disease Onset' />
          </Grid>        
        </Grid>
        <LoadingButton loading={isSubmitting} type="submit" variant="contained" >Submit</LoadingButton>
        <Button variant="outlined" component={Link} to="/dash" sx={{ marginLeft: '2em'}}>Cancel</Button>
      </Box>
    </Container>
  )
}
