import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material"
import { useController, UseControllerProps } from "react-hook-form"


interface Props extends UseControllerProps {
    label: string
}

export default function YearSelect(props: Props) {
    const {fieldState, field} = useController({...props, defaultValue: ''})
    let years = new Array()

    for (let index = 1900; index < 2050; index++) {
        years.push(index)        
    }
    
    return (
        <FormControl fullWidth error={!!fieldState.error}>
            <InputLabel>{props.label}</InputLabel>
            <Select
                value={field.value || ""}
                label={props.label}
                onChange={field.onChange}
                >
                    <MenuItem key={'null'} value={""}></MenuItem>
                    {years.map((v) => <MenuItem key={v} value={v}>{v}</MenuItem>)}
            </Select>
            <FormHelperText>{fieldState.error?.message}</FormHelperText>
        </FormControl>
    )
}
