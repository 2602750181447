import { useEffect } from 'react'
import LoadingComponent from '../../app/components/LoadingComponent';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchCalcAsync } from '../calculator/calculatorSlice';
import PatientProfile from './PatientProfile';
import { fetchPatient, fetchPatientsAsync } from './patientsSlice';

export default function EditPatient() {
  const dispatch = useAppDispatch();
  const { lookupsLoaded, lookups } = useAppSelector((state) => state.calculator)
  const { patientsLoaded, patient } = useAppSelector((state) => state.patients)
  
  useEffect(() => { if (!lookupsLoaded) dispatch(fetchCalcAsync()) }, [lookupsLoaded, dispatch]);
  useEffect(() => { if (!patientsLoaded) dispatch(fetchPatientsAsync()).then((resp) => dispatch(fetchPatient(resp.payload.patients[0]))) }, [patientsLoaded, dispatch])

  if (!lookupsLoaded || !patientsLoaded)
    return <LoadingComponent message="Loading form..." />
  
  return <PatientProfile lookups={lookups} preloadedValues={patient} title={"Edit Profile"} />;
}
