import { Button, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ukcom from '../images/ukcom.png'
import uvcom from '../images/uvcom.png'
import pshcom from '../images/pshcom.png'
import uou from '../images/uofu.png'
import colu from '../images/colu.png'
import ninds from '../images/ninds.png'

export default function HomePage() {
  return (
    <Container maxWidth={'lg'} sx={{ alignItems: 'center', p: 4 }} >
      <Grid container columnSpacing={15}>
        <Grid item component={Typography} variant="h6" sx={{ fontWeight: "800", paddingBottom: "1rem" }} xs={12}>
          Original research performed at these ALS centers:
        </Grid>
        <Grid item component={Typography} sx={{ paddingY: "1rem"}} align="center" xs={12}>
            <img src={ukcom} className="img-ukcom" alt="Univ. of Kentucky College of Medicine" />
        </Grid>
        <Grid sx={{ paddingY: "10px"}} item xs={12} sm={6} component={Typography} align="center">
          <Button sx={{ paddingY: '10px' }}fullWidth variant="contained" component={Link} to="/calc">ALS Nutrition Calculator</Button>
        </Grid>
        <Grid sx={{ paddingY: "10px"}} item xs={12} sm={6} component={Typography} align="center">
          <Button sx={{ paddingY: '10px'}} fullWidth variant="contained" component={Link} to="/login">Go to Existing Account</Button>
        </Grid>
        <Grid item component={Typography} align='center' sx={{ paddingY: "1rem" }} xs={12} sm={6} lg={3}>
          <img src={uvcom} className="img-uvcom" alt="Univ. of Vermont College of Medicine" />
        </Grid>
        <Grid item component={Typography} align='center' sx={{ paddingY: "1rem" }} xs={12} sm={6} lg={3}>
          <img src={pshcom} className="img-psh" alt="PennState Hershey College of Medicine" />
        </Grid>
        <Grid item component={Typography} align='center' sx={{ paddingY: "1rem" }} xs={12} sm={6} lg={3}>
          <img src={uou} className={"img-uofu"} alt="Univ. of Utah" />
        </Grid>
        <Grid item component={Typography} align='center' sx={{ paddingY: "1rem" }} xs={12} sm={6} lg={3}>
          <img src={colu} className={"img-colu"} alt="Columbia Univ." />
        </Grid>
        <Grid item component={Typography} variant="h6" sx={{ fontWeight: "800", paddingBottom: "1rem" }} xs={12}>
          Developed with research support from the National Institute of Neurological Disorders and Stroke (NINDS) - NS 045087
        </Grid>
        <Grid item component={Typography} align='center' sx={{ paddingY: "1rem" }} xs={12}>
          <img src={ninds}  alt="National Institute of Neurological Disorders and Stroke" />
        </Grid>        
        <Grid item component={Typography} variant="h6" sx={{ fontWeight: "800", paddingBottom: "1rem" }} xs={12}>
          Additional Support: Cynthia Shaw Crispen Endowment, Kevin Heidrich/Team 7 Endowment, ALS Hope Foundation.
        </Grid>        
      </Grid>
    </Container>
  )
}
